import React, {useRef, useState} from "react"
import NavBar from '../components/navbar';
import Footer from '../components/footer';
import { Slide } from 'react-slideshow-image';
import Youtube from 'react-youtube';
import HeadData from '../data/HeadData';

const VideoPage = (props) => {
    
    const slideRef = useRef();
    const [currentPlayer, setcurrentPlayer] = useState();
    const slideProperties = {
        autoplay: false,
        indicators: false,
        arrows: true,
        transitionDuration : 500,
        onChange: (previous, next) => {
            stopCurrent(previous);
        }
    };

    const stopCurrent = (previous) =>
    {
        if(currentPlayer !== undefined)
        {
            currentPlayer.target.stopVideo();
        }

    }

    return (
        <div className="flex flex-col">
            <HeadData title='Disc Golf Thailand Videos' />
            <NavBar key="navbar"/>
            <div className="flex flex-row w-full items-center justify-center mt-32 mb-10">
                <div className="w-11/12 sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12">
                    <Slide ref={slideRef} {...slideProperties}>
                        <div className="flex h-96 w-full items-center justify-center">                            
                            <Youtube id='v3' videoId='DOzEKalkUps' 
                                className='flex h-full w-full m-0 p-0'
                                containerClassName='flex h-full w-full m-0 p-0' 
                                onPlay={(e) => setcurrentPlayer(e)} />
                        </div>
                        <div className="flex h-96 w-full items-center justify-center">
                            <Youtube id='v1' videoId='Zbr8DwgXABg' 
                                className='flex h-full w-full m-0 p-0'
                                containerClassName='flex h-full w-full m-0 p-0'
                                onPlay={(e) => {setcurrentPlayer(e)}} />
                        </div>
                        <div className="flex h-96 w-full items-center justify-center">
                            <Youtube id='v2' videoId='1S7DOwUzHVY' 
                                className='flex h-full w-full m-0 p-0'
                                containerClassName='flex h-full w-full m-0 p-0' 
                                onPlay={(e) => setcurrentPlayer(e)} />
                        </div>
                        <div className="flex h-96 w-full items-center justify-center">                            
                            <Youtube id='v3' videoId='GnE5b0ntfq4' 
                                className='flex h-full w-full m-0 p-0'
                                containerClassName='flex h-full w-full m-0 p-0' 
                                onPlay={(e) => setcurrentPlayer(e)} />
                        </div>
                    </Slide>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default VideoPage;